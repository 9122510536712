<template>
	<div class="container my-5">
		<div class="row">
			<div class="col-8 offset-2">
				<p class="title fg-lightblue">{{title}}</p>
				<p class="content">{{ content}}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		val: '',
		editing: false
	}),

	props: {
		title: { type: String },
		content: { type: String }
	}
};
</script>

<style scoped>
p {
	text-align: center;
	margin: 0;
}

.title {
	font-weight: 600;
	text-transform: uppercase;
}
</style>
