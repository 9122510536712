<template>
	<div class="jumbotron jumbotron-fluid m-0 hero-img" :style="{ backgroundImage: 'url(' + propsObj.header_image + ')' }">
		<div class="container banner-text">
			<div class="row">
				<div class="col-md-4">
					<h2>{{propsObj.header_title}}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<p class="quote" v-html="propsObj.header_quote"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		propsObj: {
			header_image: { type: String },
			header_title: { type: String },
			header_quote: { type: String }
		}
	}
};
</script>

<style scoped>
.hero-img {
	/* background-image: url('../assets/landschap.jpg'); */
	background-position: center center;
	background-repeat: no-repeat;
	min-height: 240px;
}

@media only screen and (min-width: 576px) {
	.hero-img {
		background-size: 100%;
	}
}

.banner-text {
	color: white;
	text-align: left;
}
</style>
