<template>
	<div style="--aspect-ratio: 4" class="bg-apricot kop my-1 hovered">
		<div class="aspect-ratio-box-inside">
			<a href="/leerstrategieenbox">
				<div class="row h-100 align-items-center">
					<div class="col">
						<p class="btn-text">leerstrategieënbox</p>
					</div>
					<div class="col text-right pr-5">
						<Arrow width="40" link="/leerstrategieenbox"></Arrow>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';
export default {
	components: {
		Arrow,
	},
};
</script>

<style scoped>
.btn-text {
	text-align: left;
	margin: 0;
	padding: 0 0 0 20px;
	color: white;
	background-color: transparent;
	font-size: 20px;
	text-transform: uppercase;
}
</style>
